import React, { useEffect, useState } from "react";
import "./Shop.css";
import Header from "../../Components/Header/Header";
import Icons from "../../Components/Icons";

export default function Shop() {
  return (
    <div>
      <section>
        <Header />
      </section>
      <section className="product-pet-home">
        <img
          className="store-coming-soon"
          src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Header-footerwebp/Frame+1597885025.webp"
        />
        <h1>Everything your pet needs to thrive.</h1>
        <p>
          We're dedicated to improving the lives of pets everywhere. With
          innovative products like our Echo device, we help pets stay healthy,
          happy, and thriving. Join us in making a real difference for pets and
          their owners. Together, we can ensure every pet lives their best life.
        </p>
        <button>
          Coming soon &nbsp;
          <Icons name="shop-button-direction" />
        </button>
      </section>
    </div>
  );
}
