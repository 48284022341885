import React, { useState } from "react";
import "./Header.css";
import Menu from "./menu";
import { Link, NavLink } from "react-router-dom";
import Icons from "../Icons";
import { FaUser } from "react-icons/fa";
import { IoEarth } from "react-icons/io5";

const logo =
  "https://peteye-drive.s3.ap-south-1.amazonaws.com/Header-footerwebp/image16407.webp";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [homeSubmenuOpen, setHomeSubmenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const [isToggled, setIsToggled] = useState(false);

  // const handleToggle = () => {
  //   setIsToggled(!isToggled);
  //   if (!isToggled) {
  //     window.location.href = "https://dashboard.peteye.pet";
  //   } else {
  //     window.location.href = "https://www.peteye.pet/";
  //   }
  // };
  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const navItems = [
    { path: "/", label: "Home" },
    { path: "/Service/#service1", label: "Services" },
    // { path: "/About/#home", label: "About" },
    // { path: "/Guide/ ", label: "Guide" },
    { path: "/Shop/", label: "Shop" },
    // { path: "/career/", label: "Career" },
  ];

  return (
    <div>
      <header className="mainHeader">
        <div>
          <img className="logo" src={logo} alt="peteye logo" title="" />
        </div>

        <div className="header-homenav">
          {navItems.map((item) => (
            <NavLink
              key={item.path}
              style={{ textDecoration: "none" }}
              to={item.path}
              className={({ isActive }) =>
                isActive ? "header-homenav-text active" : "header-homenav-text"
              }
            >
              <p>{item.label}</p>
            </NavLink>
          ))}
          <div className="header-profileline"></div>
          <a
            className="header-website"
            href="https://www.dashboard.peteye.pet/"
            target="_blank"
          >
            Dashboard
          </a>
        </div>
        <div className="links">
          <div className="header-bellfaq-icons">
            <Link to="/search">
              <span>
                <Icons name={"header-search"} />
              </span>
            </Link>
            <Link to="/Shop">
              <span>
                <Icons name={"header-shop-cart"} />
              </span>
            </Link>
            <span onClick={toggleDropdown}>
              <Icons name={"header-user"} />
            </span>
            {/* <div className={dropdown-menu ${isOpen ? "open" : ""}}>
              <div className="dropdown-content">
                <div className="dropdown-item">Profile</div>
                <div className="dropdown-item">Notification</div>
                <div className="dropdown-item">Privacy & Security</div>
                <div className="dropdown-item">FAQ</div>
                <div className="dropdown-item">Logout</div>
              </div>
            </div> */}
          </div>
        </div>
      </header>
      <header className="mobile-header">
        <div>
          <img className="logo" src={logo} alt="peteye logo" title="" />
        </div>
        <div className="menu-wrapper">
          <input
            type="checkbox"
            name="check"
            id="check"
            onChange={toggleMenu}
            className="menubtn2"
          />
          <label htmlFor="check" className="hamburger-menu-container">
            <div className="hamburger-menu">
              <div></div>
            </div>
          </label>
          {menuOpen && <Menu className="nav-btn" />}
        </div>
      </header>
    </div>
  );
};

export default Header;
